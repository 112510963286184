import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import Logo from "../../assets/images/header/logo.png";
import "bootstrap/dist/css/bootstrap.min.css";

export default function HeaderSession() {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => setMenuOpen(!isMenuOpen);

  const menuLinks = [
    { label: "QUEM SOMOS", url: "https://www.prosegur.com.br/sobre-prosegur" },
    { label: "NOSSOS PRODUTOS", url: "https://www.prosegur.com.br/" },
    {
      label: "DEPÓSITO CONTA DIGITAL",
      url: "https://www.prosegur.com.br/grandes-empresas/cash-today",
    },
    { label: "AJUDA", url: "/ajuda" }, // Atualize o link
  ];

  return (
    <div>
      <Container fluid className="bg-warning">
        <Container>
          <Row className="align-items-center py-3">
            <Col className="d-flex align-items-center">
              <Link to="/">
                <img
                  src={Logo}
                  alt="logo"
                  className="img-fluid"
                  style={{ width: "180px" }}
                />
              </Link>
            </Col>

            <Col xs={6} className="d-none d-xl-flex justify-content-center">
              <nav className="navmenu d-flex flex-wrap justify-content-center gap-4">
                {menuLinks.map((item, index) => (
                  <Link
                    key={index}
                    to={item.url}
                    className="text-dark text-decoration-none fw-bold"
                  >
                    {item.label}
                  </Link>
                ))}
              </nav>
            </Col>

            <Col className="d-flex justify-content-end align-items-center">
              <div className="d-none d-xl-flex gap-2">
                <button
                  className="btn btn-dark px-4 text-nowrap"
                  onClick={() =>
                    window.location.replace(
                      "https://ibk.hml.prosegurdigital.com.br/"
                    )
                  }
                >
                  Acessar conta
                </button>
                <button
                  className="btn btn-dark px-4 text-nowrap"
                  onClick={() =>
                    window.location.replace(
                      "https://ibk.hml.prosegurdigital.com.br/tipo-de-conta"
                    )
                  }
                >
                  Criar conta
                </button>
              </div>

              <button
                className="navbar-toggler d-xl-none"
                type="button"
                onClick={toggleMenu}
                style={{ border: "none", background: "transparent" }}
              >
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3 6h18M3 12h18M3 18h18"
                    stroke="#000"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </button>
            </Col>
          </Row>
        </Container>

        {isMenuOpen && (
          <Row className="d-lg-none bg-warning">
            <Col className="d-flex flex-column align-items-center py-3">
              {menuLinks.map((item, index) => (
                <Link
                  key={index}
                  to={item.url}
                  className="py-2 text-dark text-decoration-none text-center fw-bold"
                >
                  {item.label}
                </Link>
              ))}
              <button
                className="btn btn-dark my-2 text-nowrap"
                onClick={() =>
                  window.location.replace(
                    "https://ibk.hml.prosegurdigital.com.br/"
                  )
                }
              >
                Acessar conta
              </button>
              <button
                className="btn btn-dark my-2 text-nowrap"
                onClick={() =>
                  window.location.replace(
                    "https://ibk.hml.prosegurdigital.com.br/tipo-de-conta"
                  )
                }
              >
                Criar conta
              </button>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
}
